<template>
	<div>

		<el-form :inline="true">
			<el-form-item>
				<el-button type="primary" @click="dialogVisible = true">新增</el-button>
			</el-form-item>
		</el-form>
<!--
default-expand-all  默认打开
-->

		<el-table
				:data="tableData"
				style="width: 100%;margin-bottom: 20px;"
				row-key="id"
				border
				stripe
				:tree-props="{children: 'children', hasChildren: 'hasChildren'}">

      <el-table-column
          prop="label"
          label="字典名称">
      </el-table-column>

      <el-table-column
          prop="value" width="300px"
          label="字典编码">
      </el-table-column>

      <el-table-column
          prop="remark" width="400px"
          label="备注说明">
      </el-table-column>

      <el-table-column
          prop="statu"
          label="状态" width="150">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.statu === 1" type="success">正常</el-tag>
          <el-tag size="small" v-else-if="scope.row.statu === 0" type="danger">禁用</el-tag>
        </template>

      </el-table-column>
      <el-table-column
          prop="icon"
          width="360px"
          label="操作">

        <template slot-scope="scope">
          <el-button type="text" @click="editHandle(scope.row.id)">编辑</el-button>
          <el-divider direction="vertical"></el-divider>

          <template>
            <el-popconfirm title="这是一段内容确定删除吗？" @confirm="delHandle(scope.row.id)">
              <el-button type="text" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>

        </template>
      </el-table-column>

		</el-table>


		<!--新增对话框-->
		<el-dialog
				title="提示"
				:visible.sync="dialogVisible"
				width="600px"
				:before-close="handleClose">

			<el-form :model="editForm" :rules="editFormRules" ref="editForm"
               label-width="100px" class="demo-editForm">

				<el-form-item label="上级字段" prop="parentId">
					<el-select v-model="editForm.pid" placeholder="请选择上级字段">
            <el-option label="顶级" :value="0"></el-option>
						<template v-for="item in tableData">
							<el-option :label="item.label" :value="item.id"></el-option>
						</template>
					</el-select>
				</el-form-item>

        <el-form-item label="字典名称" prop="label" label-width="100px">
          <el-input v-model="editForm.label" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="字典编码"  prop="value" label-width="100px">
          <el-input v-model="editForm.value" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注"  prop="remark" label-width="100px">
          <el-input v-model="editForm.remark" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="状态"  prop="statu" label-width="100px">
          <el-radio-group v-model="editForm.statu">
            <el-radio :label="0">禁用</el-radio>
            <el-radio :label="1">正常</el-radio>
          </el-radio-group>
        </el-form-item>


				<el-form-item>
					<el-button type="primary" @click="submitForm('editForm')">立即创建</el-button>
					<el-button @click="resetForm('editForm')">重置</el-button>
				</el-form-item>
			</el-form>

		</el-dialog>


	</div>

</template>

<script>
	export default {
		name: "Dict",
		data() {
			return {
				dialogVisible: false,
				editForm: {

				},
				editFormRules: {
					pid: [
						{required: true, message: '请选择上级菜单', trigger: 'blur'}
					],
          label: [
            {required: true, message: '字典名称', trigger: 'blur'}
          ],
          value: [
            {required: true, message: '字典编码', trigger: 'blur'}
          ],
          statu: [
            {required: true, message: '请选择状态', trigger: 'blur'}
          ]
				},
				tableData: []
			}
		},
		created() {
			this.getMenuTree()
		},
		methods: {
			getMenuTree() {
				this.$axios.get("/system/dict/list").then(res => {
					this.tableData = res.data.data
				})
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$axios.post('/system/dict/' + (this.editForm.id?'update' : 'save'), this.editForm)
							.then(res => {

                this.$message({
									showClose: true,
									message: '恭喜你，操作成功',
									type: 'success',
									onClose:() => {
										this.getMenuTree()
									}
								});
                this.resetForm('editForm')
              })
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			editHandle(id) {
				this.$axios.get('/system/dict/info/' + id).then(res => {
					this.editForm = res.data.data

					this.dialogVisible = true
				})
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.dialogVisible = false
				this.editForm = {}
			},
			handleClose() {
				this.resetForm('editForm')
			},
			delHandle(id) {
				this.$axios.post("/system/dict/delete/" + id).then(res => {
					this.$message({
						showClose: true,
						message: '恭喜你，操作成功',
						type: 'success',
						onClose:() => {
							this.getMenuTree()
						}
					});

				})
			}
		}
	}
</script>

<style scoped>

</style>
